<template>
  <JoszakiAutocomplete
    v-model="searchValue"
    :placeholder="$t('navbar.search.placeholder')"
    :items="items"
    items-comparator="isLastItem"
    group-id-field="group.id"
    group-value-field="group.name"
    group-comparator="group.order"
    :loading="loading"
    true-autocomplete-mode
    @input="onSearchInput"
    @select="onItemSelected"
    @focus="$emit('focus')"
  >
    <template #empty>
      <span>
        {{
          searchValue.length > 3
            ? $t("common.noResults")
            : $t("navbar.search.minLengthMessage")
        }}
      </span>
    </template>
  </JoszakiAutocomplete>
</template>

<script>
import { nextTick, ref, useContext, useRouter } from "@nuxtjs/composition-api";
import SEARCH_QUERY from "./search.graphql";
import { debounce } from "~/helpers/debounce";

export default {
  name: "AutocompleteSearch",
  setup() {
    const { $query, localePath, i18n, $logger } = useContext();
    const router = useRouter();
    const searchValue = ref("");
    const items = ref([]);
    const loading = ref(false);

    const onSearchInput = debounce(async function (search) {
      if (search.length < 3) {
        items.value = [];
        return;
      }
      loading.value = true;
      try {
        const { professionals, professions, professionTasks } = await $query(
          SEARCH_QUERY,
          { search: search.toLowerCase() }
        );

        const professionsGroup = {
          id: "professions",
          name: i18n.t("navbar.search.professions"),
          order: 1,
        };
        const pricesGroup = {
          id: "prices",
          name: i18n.t("navbar.search.prices"),
          order: 2,
        };
        const professionalsGroup = {
          id: "professionals",
          name: i18n.t("navbar.search.professionals"),
          order: 3,
        };

        const professionItems = professions.map((p) => ({
          ...p,
          to: localePath({
            name: "professionPage",
            params: { profession: p.seoName },
          }),
          isLastItem: false,
          group: professionsGroup,
        }));

        const priceItems = professionTasks.map((p) => ({
          ...p,
          to: localePath({
            name: "prices",
            params: { professionTask: p.seoName },
          }),
          isLastItem: false,
          group: pricesGroup,
        }));

        const professionalItems = professionals.people.map((p) => ({
          ...p,
          to: localePath({
            name: "professionalPage",
            params: { professionalSeoName: p.seoName },
          }),
          isLastItem: false,
          group: professionalsGroup,
        }));

        items.value = [
          ...professionItems,
          ...priceItems,
          ...professionalItems,
          {
            id: -1,
            name: i18n.t("navbar.search.allProfessions"),
            to: localePath({ name: "professionsLandingPage" }),
            group: professionsGroup,
            isLastItem: true,
          },
          {
            id: -2,
            name: i18n.t("navbar.search.allPrice"),
            to: localePath({ name: "pricesPage" }),
            group: pricesGroup,
            isLastItem: true,
          },
        ];
      } catch (e) {
        $logger.error(e);
      } finally {
        loading.value = false;
      }
    }, 500);

    const onItemSelected = (item) => {
      if (!item) {
        return;
      }
      router.push(item.to);

      nextTick(() => {
        searchValue.value = "";
        items.value = [];
      });
    };

    return {
      searchValue,
      items,
      loading,
      onSearchInput,
      onItemSelected,
    };
  },
};
</script>
