<template>
  <div
    class="wrapper"
    :class="{ open: showAdmin, [$route.query?.referrer ?? '']: true }"
  >
    <PublicSiteNavBar v-if="!noBrandActive" />
    <ModalTender v-if="isNewTender && newTender.state.modalOpen" />
    <AdminDialogCreditTopUp />
    <JoszakiToastContainer />
    <JoszakiAlert />
    <JoszakiConfirm />
    <div id="main-body" class="main-body">
      <Nuxt />
    </div>
    <template v-if="isAdmin">
      <AdminPanel class="admin-panel" :class="{ open: showAdmin }" />
      <NuxtLink
        v-if="!showAdmin"
        class="fixed z-100 bottom-8 right-32 bg-primary-light w-16 h-16 rounded-full drop-shadow-md flex justify-center items-center !text-white text-2xl hover:bg-primary-dark hover:drop-shadow-lg transition-all duration-300"
        :title="$t('adminPanel.conductorButtonTitle')"
        :to="
          localePath({
            name: 'conductorPersonsEditPage',
            params: { id: privateData?.id },
          })
        "
      >
        {{ $t("adminPanel.conductorButton") }}
      </NuxtLink>
      <div
        v-if="!showAdmin"
        class="fixed cursor-pointer z-100 bottom-8 right-8 border border-warning-darker bg-warning w-16 h-16 rounded-full drop-shadow-md flex justify-center items-center !text-white text-2xl hover:bg-warning-darker hover:drop-shadow-lg transition-all duration-300"
        :title="$t('adminPanel.title')"
        @click="toggleAdmin"
      >
        <IconComponent icon="user-cog" />
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "@nuxtjs/composition-api";
import { mapState } from "pinia";
import { useAdminStore } from "~/stores/admin";
import PublicSiteNavBar from "~/components/_refactored/header/PublicSiteNavBar.vue";
import JoszakiAlert from "~/components/joszaki/JoszakiAlert.vue";
import { useUserStore } from "~/stores/user";
import AdminPanel from "~/components/_refactored/admin/AdminPanel.vue";
import JoszakiToastContainer from "~/components/_refactored/common/JoszakiToastContainer.vue";
import JoszakiConfirm from "~/components/joszaki/JoszakiConfirm.vue";
import AdminDialogCreditTopUp from "~/components/_refactored/admin/AdminDialogCreditTopUp.vue";
import { useAdminUserSwitcher } from "~/components/_refactored/admin/useAdminUserSwitcher";
import ModalTender from "~/components/newTender/ModalTender.vue";
import { useNewTender } from "~/composables/tender/useNewTender";
import { useNoBrandActive } from "~/composables/useNoBrandActive";
import { useAdminProfessionalStore } from "~/stores/admin/professional";

export default defineComponent({
  components: {
    JoszakiAlert,
    AdminPanel,
    JoszakiToastContainer,
    JoszakiConfirm,
    AdminDialogCreditTopUp,
    ModalTender,
    PublicSiteNavBar,
  },
  setup() {
    const newTender = useNewTender();
    const noBrandActive = useNoBrandActive();

    return {
      newTender,
      adminStore: useAdminStore(),
      noBrandActive,
    };
  },
  fetchOnServer: true,
  computed: {
    ...mapState(useAdminProfessionalStore, {
      privateData: "privateData",
    }),
    ...mapState(useAdminUserSwitcher, {
      switchedToMate: "switchedToMate",
    }),
    ...mapState(useUserStore, {
      me: "me",
    }),
    showAdmin() {
      return this.adminStore.panelOpen;
    },
    isAdmin() {
      return this.switchedToMate || this.me?.isAdmin;
    },
    isNewTender() {
      return (
        this.$ab.isVariant("new-tender-2", "variant1") ||
        this.$ab.isVariant("new-tender-2", "variant2")
      );
    },
  },
  mounted() {
    if (this.me?.isAdmin) {
      this.adminStore.restorePanelState();
    }
  },
  methods: {
    toggleAdmin() {
      if (this.me?.isAdmin) {
        this.adminStore.togglePanel();
      }
    },
  },
});
</script>

<style lang="scss">
@use "styles/_all-members" as global;

.wrapper {
  display: grid;
  grid-template-areas:
    "header"
    "main"
    "footer";
  grid-template-columns: auto;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  // overflow: hidden;
  width: 100%;
}

main {
  flex: 1;
}

.main-body {
  // scroll-behavior: smooth;
  grid-area: main;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  height: 100%;
  overflow: auto;
  isolation: isolate;
}

.admin-container {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

.admin-scroll {
  height: 100%;
  width: 100%;
  overflow: auto;
}

$admin-panel-height: 35rem;

.admin-panel {
  isolation: isolate;
  position: relative;
  height: 0;
  transition: all 0.2s ease;
  overflow: hidden;

  &.open {
    height: $admin-panel-height;
  }
}

.admin-table {
  width: 100%;

  td {
    width: 50%;
    padding: 0.4rem 0.5rem;
    border: 1px solid #ddd;
  }
}
</style>
